import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Checkbox,
  Grid,
  IconButton,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Box,
  Button,
  Radio,
  TextField,
} from "@mui/material";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

/*
Filter specification:

{
  label: str,
  filter: (row) => bool,
  startsActive: bool
}

*/

function SearchableTable(props) {
  const searchFields = props.cols
    .filter((col) => col.type === "text")
    .map((col) => col.key);

  const [selectedRows, setSelectedRows] = useState({});
  const [rangeStart, setRangeStart] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  const Highlight = (props) => {
    const renderText = () => {
      const regex = new RegExp(props.searchText, "gi");
      return String(props.text).replace(
        regex,
        (match) =>
          `<span style="background: rgba(245, 0, 87, 0.25);">${match}</span>`
      );
    };

    return <div dangerouslySetInnerHTML={{ __html: renderText() }}></div>;
  };

  const filterBySearchText = (searchText) => (row) => {
    return searchFields.some((field) =>
      String(row[field])
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase())
    );
  };

  const filterRows = (searchText, filtersToApply) => {
    let filteredRows = props.rows;
    // Filter with filers
    props.filters.forEach((filter) => {
      if (filtersToApply.includes(filter.label)) {
        filteredRows = filteredRows.filter((row) => filter.filter(row));
      }
    });
    // Filter with text
    if (searchText.length > 0) {
      filteredRows = filteredRows.filter(filterBySearchText(searchText));
    }
    setFilteredRows(filteredRows);
  };

  const updateSearch = (newSearchText) => {
    setRangeStart(0);
    setSearchText(newSearchText);
    filterRows(newSearchText, activeFilters);
  };

  const hasPreviousPage = () => {
    return rangeStart - props.rowsPerPage >= 0;
  };

  const hasNextPage = () => {
    return rangeStart + props.rowsPerPage < filteredRows.length;
  };

  const displayNextPage = () => {
    setRangeStart(rangeStart + props.rowsPerPage);
  };

  const displayPreviousPage = () => {
    setRangeStart(rangeStart - props.rowsPerPage);
  };

  const renderPageInfo = () => {
    if (filteredRows.length === 0) {
      return "";
    }
    return `${rangeStart + 1}-${Math.min(
      rangeStart + props.rowsPerPage,
      filteredRows.length
    )} of ${filteredRows.length}`;
  };

  const sortRows = (key, reverse = false) => {
    let sorted = [...filteredRows];
    sorted.sort((row1, row2) =>
      row1[key] < row2[key] ? -1 : row1[key] > row2[key] ? 1 : 0
    );
    if (reverse) {
      sorted.reverse();
    }
    setFilteredRows(sorted);
  };

  const renderSortControls = (col) => {
    if (!!props.sortable) return null;
    if (!(col.type === "text")) return null;

    return (
      <>
        <Grid item>
          <IconButton size="small" onClick={() => sortRows(col.key)}>
            <ArrowDropDownIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton size="small" onClick={() => sortRows(col.key, true)}>
            <ArrowDropUpIcon />
          </IconButton>
        </Grid>
      </>
    );
  };

  const renderHeader = () => (
    <TableHead>
      <TableRow>
        {props.selectMode && <TableCell />}
        {props.displayIds && <TableCell>Id</TableCell>}
        {props.cols.map((col, colIndex) => (
          <TableCell align="left" key={`header-${colIndex}`}>
            <Grid container direction="row" alignItems="center">
              <Grid item>{col.label}</Grid>
              {renderSortControls(col)}
            </Grid>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const handleRowClick = (row) => {
    if (props.selectMode === "multiple") {
      if (selectedRows[row.id]) {
        setSelectedRows({ ...selectedRows, [row.id]: false });
        if (props.rowSelectionCallback) {
          props.rowSelectionCallback(row, false);
        }
      } else {
        setSelectedRows({ ...selectedRows, [row.id]: true });
        if (props.rowSelectionCallback) {
          props.rowSelectionCallback(row, true);
        }
      }
    } else if (props.selectMode === "single") {
      if (!selectedRows[row.id]) {
        setSelectedRows({ [row.id]: true });
        props.rowSelectionCallback(row, true);
      }
    }
  };

  const numberOfColumns = () => {
    return props.cols.length + !!props.selectMode + props.displayIds;
  };

  const renderBody = () => {
    if (props.isLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell align="center" colSpan={numberOfColumns()}>
              <CircularProgress />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (filteredRows.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell align="center" colSpan={numberOfColumns()}>
              {props.rows.length === 0 ? "No rows" : "No results"}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return <TableBody>{renderRows()}</TableBody>;
  };

  const renderRowSelector = (row) => {
    if (props.selectMode === "multiple") {
      return (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={!!selectedRows[row.id]}
            onChange={() => handleRowClick(row)}
          />
        </TableCell>
      );
    } else if (props.selectMode === "single") {
      return (
        <TableCell padding="checkbox">
          <Radio
            color="primary"
            checked={!!selectedRows[row.id]}
            onChange={() => handleRowClick(row)}
          />
        </TableCell>
      );
    }
    return null;
  };

  const renderRows = () => {
    let range = [...Array(props.rowsPerPage).keys()].map((i) => i + rangeStart);
    range = range.filter((i) => i < filteredRows.length);

    return range.map((rowIndex) => {
      const row = filteredRows[rowIndex];
      return (
        <TableRow key={`row-${rowIndex}`}>
          {renderRowSelector(row)}
          {props.displayIds && <TableCell>{row.id}</TableCell>}
          {props.cols.map((col, colIndex) => (
            <TableCell align="left" key={`cell-${rowIndex}-${colIndex}`}>
              {renderCell(row, col, rowIndex, colIndex)}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  const renderCell = (row, col, rowIndex, colIndex) => {
    if (col.type === "text") {
      return (
        <Highlight
          searchText={searchText}
          text={String(row[col.key]) || col.default || ""}
        />
      );
    }
    if (col.type === "component") {
      return col.render(row, col, rowIndex, colIndex);
    }
  };

  const renderTable = () => (
    <Paper elevation={2}>
      <Paper variant="outlined">
        <TableContainer>
          <Table stickyHeader>
            {renderHeader()}
            {renderBody()}
          </Table>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="caption">{renderPageInfo()}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                disabled={!hasPreviousPage()}
                size="small"
                onClick={displayPreviousPage}
              >
                <ArrowLeftIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                disabled={!hasNextPage()}
                size="small"
                onClick={displayNextPage}
              >
                <ArrowRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableContainer>
      </Paper>
    </Paper>
  );

  const renderSearchBar = () =>
    props.searchable && (
      <Box my={2}>
        <FormControl fullWidth variant="outlined">
          <TextField
            label="Search"
            value={searchText}
            onChange={(event) => updateSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchText ? (
                <IconButton onClick={() => updateSearch("")}>
                  <HighlightOffIcon />
                </IconButton>
              ) : null,
            }}
          />
        </FormControl>
      </Box>
    );

  const toggleFilter = (filter) => {
    const isActive = activeFilters.includes(filter.label);
    const newFilters = isActive
      ? activeFilters.filter((label) => label !== filter.label)
      : [...activeFilters, filter.label];
    setActiveFilters(newFilters);
    filterRows(searchText, newFilters);
  };

  const renderFilter = (filter) => (
    <Button
      key={filter.label}
      variant="outlined"
      size="small"
      color={activeFilters.includes(filter.label) ? "primary" : undefined}
      endIcon={activeFilters.includes(filter.label) && <CheckIcon />}
      onClick={() => toggleFilter(filter)}
    >
      {filter.label}
    </Button>
  );

  const renderFilters = () => (
    <Box mx={1} my={2}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {props.filters.map((filter) => renderFilter(filter))}
      </Grid>
    </Box>
  );

  useEffect(() => {
    const initialActiveFilters = props.filters.filter((f) => f.startsActive);
    setActiveFilters(initialActiveFilters.map((f) => f.label));
    let initialRows = props.rows;
    initialActiveFilters.forEach((f) => {
      initialRows = initialRows.filter(f.filter);
    });
    setFilteredRows(initialRows);
  }, [props.rows, props.filters]);

  useEffect(() => {
    const selectedRows = {};
    props.initialSelection.forEach((id) => {
      selectedRows[id] = true;
    });
    setSelectedRows(selectedRows);
    props.filters.forEach((filter) => {
      if (filter.startsActive) {
        toggleFilter(filter);
      }
    });
  }, []);

  return (
    <Box>
      {renderSearchBar()}
      {renderFilters()}
      {renderTable()}
    </Box>
  );
}

SearchableTable.propTypes = {
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  displayIds: PropTypes.bool,
  isLoading: PropTypes.bool,
  filters: PropTypes.array,
  rowSelectionCallback: PropTypes.func,
  sortable: PropTypes.bool,
  selectMode: PropTypes.string,
  initialSelection: PropTypes.array,
  searchable: PropTypes.bool,
};

SearchableTable.defaultProps = {
  cols: [],
  rows: [],
  rowsPerPage: 8,
  displayIds: false,
  isLoading: false,
  filters: [],
  sortable: true,
  selectMode: null,
  initialSelection: [],
  searchable: true,
};

export default SearchableTable;
